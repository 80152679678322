<script setup>
import { ref, reactive, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanPenjualanService from '@/services/LaporanPenjualanService'
import ObatService from '@/services/ObatService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanPenjualanService()
const obatService = new ObatService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let form = reactive({
  date_from: null,
  date_to: null,
  obat: null,
})
let submitted = ref(false)
let acObat = ref([])

const rules = {
  obat: {
    required: helpers.withMessage('Barang harus diisi.', required),
  },
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

function searchObat(e) {
  obatService
    .get(`?filter[nama]=${e.query}&filter[status]=aktif`)
    .then((res) => {
      acObat.value = res.data.data
    })
    .catch((err) => {
      errorHandler(err, 'Data Obat', toast)
    })
}

function onExport(isFormValid) {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let obatId = 0
  if (form.obat) obatId = form.obat.id

  submitted.value = true

  if (!isFormValid) {
    isLoadingExport.value = false
    return
  }

  laporanService
    .exportLaporanPenjualanItemBarang(startDate, endDate, obatId)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-penjualan-item-barang.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Penjualan Per Item Barang', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.date_to = null
  form.date_from = null
  form.obat = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Penjualan Per Item Barang"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form @submit.prevent="onExport(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-4">
            <AutoComplete
              v-model="v$.obat.$model"
              :min-length="2"
              :suggestions="acObat"
              field="nama"
              class="w-full"
              input-class="w-full"
              placeholder="Barang"
              autofocus
              :class="{
                'p-invalid': v$.obat.$invalid && submitted,
              }"
              @complete="searchObat($event)"
            >
            </AutoComplete>
            <small
              v-if="
                (v$.obat.$invalid && submitted) ||
                v$.obat.$pending.$response
              "
              class="p-error"
              >{{ v$.obat.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button
              :loading="isLoadingExport"
              type="submit"
              icon="pi pi-file-pdf"
              class="ml-2"
              label="Cetak"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
